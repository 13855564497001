import React, { Component } from 'react';
import { Link } from 'gatsby';
import { ComponentWrapper } from './Watch.css';
import { formatDistanceToNow } from 'date-fns';

class Watch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      watch: null
    };
  }

  wrapTableElements = () => {
    let el = document.getElementsByTagName('table');
    if (el.length > 0) {
      for (var i = 0; i < el.length; i++){
        let wrapper = document.createElement('div');
        el[i].parentNode.insertBefore(wrapper, el[i]);
        wrapper.className = 'table-wrapper';
        wrapper.appendChild(el[i]);
      }
    }
  }

  componentDidMount() {
    fetch(`https://esports.hirezstudios.com/esportsAPI/smite/watch/${this.props.slug}`)
    .then(response => response.json())
    .then(response => {
      console.log
      this.setState({watch: response});
    });
  }

  componentDidUpdate() {
    this.wrapTableElements();
  }

  render() {
    const { watch } = this.state;
    return (
      <ComponentWrapper className={`c-Watch`}>
      {watch && watch[0] &&
        <div className="news-container read">
          {watch[0].format !== 'video' && 
          <header style={{backgroundImage: `url('${watch[0].featured_image}')`}}></header>
          }
          <section className="post" style={{paddingTop: (watch[0].format === 'video' ? '3rem' : '0')}}>
            <div className="post__meta-info">
              <h2>{watch[0].title}</h2>
              <div className="post__meta-social">
                <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://esports.smitegame.com/vods/${this.props.slug}`} className="facebook"><i className="icon-facebook"></i></a>
                <a target="_blank" href={`https://twitter.com/home?status=https://esports.smitegame.com/vods/${this.props.slug}`} className="twitter"><i className="icon-twitter"></i></a>
              </div>
              <time></time>
              <div className="post__meta-tags">
              TAGS:
                {watch[0].real_categories && watch[0].real_categories.split(',').map((tag) =>
                  <Link key={tag} to={`/news?s=${tag.replace(/\s+/g, '-').toLowerCase()}`}> {tag}</Link>
                )}
              </div>
            </div>
            
            {watch[0].hasOwnProperty('video_id') ?
            (<iframe src={`https://www.youtube.com/embed/${watch[0].video_id}?rel=0&amp;showinfo=0`}frameBorder="0" allowFullScreen></iframe>)
            :
            (<Watch dangerouslySetInnerHTML={{__html: watch[0].content}} />)
            }
          </section>
        </div>
      }
      </ComponentWrapper>
    );
  }
}

export default Watch;